
import { TenorSearch } from "@/services/tenorService";
import { defineComponent, PropType } from "@vue/runtime-core";
import { useWindowProperties } from "@/utils/windowProperties";
import { insert } from "text-field-edit";

export default defineComponent({
  props: {
    items: Array as PropType<TenorSearch[]>,
    inputElement: {
      type: Object as PropType<HTMLTextAreaElement>,
      required: true,
    },
  },
  methods: {
    onClick(url: string) {
      insert(this.inputElement, url + " ");
      this.inputElement.focus();
      this.$emit("close");
    },
  },
  computed: {
    isWindowFocus() {
      return useWindowProperties().isFocused;
    },
  },
});
